import React from "react";

import Navbar from "./Navigation";
import Sidebar from "./Sidebar";
import Axios from "axios";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

class AdvertisementList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            url: process.env.MIX_REACT_API_URL,
            add_new: false,
            edit_mode: false,
            title: "",
            description: "",
            image: null,
            redirection_link: "",
            all_advertisements: [],
            draggedRowIndex: null,
            isAllowedReorder: true
        }
    }
    componentDidMount = (e) => {
        if (!sessionStorage.getItem("_access")) {
            window.location.href = '/admin/login';
        }
        this.getAdvertisements();
    }
    handleInput(e) {
        this.setState({ [e.target.name]: e.target.value })
    }
    handleImage = e => {
        const file = e.target.files[0];

        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            this.setState({ image: file })
            img.onload = function () {
                const width = img.width;
                const height = img.height;
                const aspectRatio = width / height;

                if (Math.abs(aspectRatio - (64 / 9)) < 0.25) {
                    console.log("success file", e.target.files)
                    console.log("success file", file)
                } else {
                    NotificationManager.error("error", "Image must have a 64:9 aspect ratio (e.g., 1280x180px).");
                    e.target.value = "";
                    this.setState({ image: null })
                }
                // Free memory
                URL.revokeObjectURL(img.src);
            }.bind(this);
        }
    }
    getAdvertisements = () => {
        Axios.get(`${this.state.url}/api/get/advertisements-list`, {
            headers: {
                Authorization: " Bearer " + sessionStorage.getItem("_access"),
            }
        }).then(res => {

            this.setState({
                all_advertisements: res.data.data,
                isAllowedReorder: res.data.data.length > 1
            })
            console.log("all response", res)
        })
    }
    AddAdvertisement = e => {
        if (this.state.title === "") {
            NotificationManager.error("error", "Please enter title.");
            return false;
        }
        if (this.state.description === "") {
            NotificationManager.error("error", "Please enter description.");
            return false;
        }
        if (this.state.redirection_link === "") {
            NotificationManager.error("error", "Please enter redirection link.");
            return false;
        }
        const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[\w]{2,}(\/\S*)?$/;
        if (!urlPattern.test(this.state.redirection_link)) {
            NotificationManager.error("error", "Please enter valid redirection link.");
            return false;
        }
        if (this.state.image === null) {
            NotificationManager.error("error", "Please select image.");
            return false;
        }
        const formData = new FormData();
        formData.append("title", this.state.title);
        formData.append("description", this.state.description);
        formData.append("image", this.state.image);
        formData.append("redirect_value", this.state.redirection_link);
        formData.append("redirect", "link");

        Axios.post(`${this.state.url}/api/advertisements`, formData, {
            headers: {
                Authorization: " Bearer " + sessionStorage.getItem("_access"),
                'content-type': 'multipart/form-data'
            }
        }).then(res => {
            console.log(res)
            this.setState({
                title: "",
                description: "",
                image: null,
                redirection_link: "",
                add_new: false,
                edit_mode: false
            });
            NotificationManager.success("success", "Advertisement added successfully.");
            this.getAdvertisements();
        })
    }
    AddNew = (e) => {
        this.setState({
            add_new: true,
            edit_mode: false
        });
    }
    cancelForm = e => {
        this.setState({
            title: "",
            description: "",
            image: null,
            redirection_link: "",
            add_new: false,
            edit_mode: false
        });
    }
    handleDragStart = (e, index) => {
        this.setState({
            draggedRowIndex: index
        });
    }
    handleDragOver = (e, index) => {
        e.preventDefault();
        const draggedOverItemIndex = index;
        const draggedItemIndex = this.state.draggedRowIndex;
        if (draggedOverItemIndex === draggedItemIndex) {
            return;
        }
        const items = [...this.state.all_advertisements];
        const draggedItem = items[draggedItemIndex];
        items.splice(draggedItemIndex, 1);
        items.splice(draggedOverItemIndex, 0, draggedItem);
        this.setState({
            all_advertisements: items,
            draggedRowIndex: draggedOverItemIndex
        });
    }
    handleDragEnd = () => {
        this.setState({
            draggedRowIndex: null,
            isAllowedReorder: false
        });

        const idsArray = this.state.all_advertisements.map(item => item.id);

        Axios.post(
            `${this.state.url}/api/advertisements/changeorderno`,
            {
                advertisement_ids: idsArray
            },
            {
                headers: {
                    Authorization: " Bearer " + sessionStorage.getItem("_access"),
                }
            }
        ).then(res => {
            console.log(res)
            if (res.data.status) {
                NotificationManager.success(
                    "success",
                    "Advertisement Ordered successfully" 
                );
            } else {
                NotificationManager.error(
                    "error",
                    "Could not update status"
                );
            }
            this.setState({
                isAllowedReorder: true
            });
        }).catch((error) => {
            console.log("An error occurred:", error);
            NotificationManager.error("error", "An error occurred");
            this.setState({
                isAllowedReorder: true
            });
        });
    };
    deleteAdvertisement = (e, id) => {
        e.preventDefault();
        Axios.delete(`${this.state.url}/api/advertisements/${id}`, {
            headers: {
                Authorization: " Bearer " + sessionStorage.getItem("_access"),
            }
        }).then(res => {
            console.log(res)
            NotificationManager.success("success", "Advertisement deleted successfully.");
            this.getAdvertisements();
        }
        )
    };
    render() {
        return (<>
            <NotificationContainer />
            <Navbar page="Admin Dashboard" />
            <Sidebar />

            <div class="content-wrapper">
                <div class="content-header">
                    <div class="container-fluid">
                        <div class="row mb-2">
                            <div class="col-sm-9">
                                <h1 class="m-0">Advertisements</h1>
                            </div>
                            <div class="col-sm-3">
                                {!this.state.add_new && !this.state.edit_mode && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.AddNew(e) }}>Add New</a></div> </>}
                                {(this.state.add_new || this.state.edit_mode) && <> <div class="add-new"><a href="javascript:void(0)" onClick={(e) => { this.cancelForm(e) }}>Back</a></div> </>}
                            </div>
                        </div>
                    </div>
                </div>
                {(this.state.edit_mode || this.state.add_new) && <> <section class="content">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card card-primary">
                                    <div class="card-header">
                                        <div class="row">
                                            <div class="col-md-9">
                                                <h3 class="card-title">Add New Advertisement</h3>
                                            </div>
                                            <div class="col-md-3">
                                            </div>
                                        </div>
                                    </div>
                                    <form>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Title</label>
                                                        <input type="text" value={this.state.title} class="form-control" name="title" onChange={e => { this.handleInput(e) }} placeholder="Enter Title" />
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Description</label>
                                                        <textarea type="text" defaultValue={this.state.description} class="form-control" name="description" onChange={e => { this.handleInput(e) }} placeholder="Enter Description" ></textarea>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Image</label>
                                                        <input type="file" accept="image/*" class="form-control" name="image" onChange={e => { this.handleImage(e) }} />
                                                        <small class="form-text text-muted">Please upload an image with a 64:9 aspect ratio (e.g., 1280x180px) in JPG, PNG format.</small>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group">
                                                        <label>Redirection Link</label>
                                                        <input type="text" value={this.state.redirection_link} class="form-control" name="redirection_link" onChange={e => { this.handleInput(e) }} placeholder="Enter Link" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card-footer submit-btn">
                                            <button type="button" onClick={e => { this.AddAdvertisement(e) }} class="btn btn-primary">Submit</button>
                                            <button type="button" onClick={e => { this.cancelForm(e) }} class="btn btn-primary">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
                {!this.state.add_new && !this.state.edit_mode && <> <section class="table-sec">
                    <div class="container-fluid">
                        <div class="col-md-12">
                            <div class="start-table-sec">
                                <div class="table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>NO</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Image</th>
                                                <th>Redirection</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.all_advertisements.length > 0 && <>
                                                {this.state.all_advertisements.map((list, index) => {
                                                    return <tr
                                                        key={index}
                                                        draggable={this.state.isAllowedReorder}
                                                        onDragStart={(e) => this.handleDragStart(e, index)}
                                                        onDragOver={(e) => this.handleDragOver(e, index)}
                                                        onDragEnd={this.handleDragEnd}
                                                    >
                                                        <td>{index + 1}</td>
                                                        <td>{list.title}</td>
                                                        <td>{list.description}</td>
                                                        <td>
                                                            <img src={list.image} alt="" style={{ width: "384px", height: "54px" }} />
                                                        </td>
                                                        <td>{list.redirect_value}</td>
                                                        <td class="edit-icon d-flex justify-content-between align-items-center">
                                                            <span role="button" tabindex="0" style={{ cursor: 'pointer' }}>
                                                                <i
                                                                    class="fa fa-trash  text-danger cursor-pointer"
                                                                    aria-hidden="true"
                                                                    onClick={(e) => { this.deleteAdvertisement(e, list.id) }}
                                                                ></i>
                                                            </span>
                                                            { this.state.isAllowedReorder && <div style={{ cursor: "grab", fontSize: "18px", textAlign: "center" }}>≡</div> }
                                                        </td>
                                                    </tr>
                                                })}
                                            </>}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                </>}
            </div>
        </>
        )
    }
}

export default AdvertisementList;